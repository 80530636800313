<template>
  <div class="pl-3">
    <h3>Bio Info</h3>
    <div class="py-2">
      <div class="font-weight-bold">Name</div>
      <div>{{buyer.name}}</div>
    </div>
    <div class="py-2">
      <div class="font-weight-bold">Phone Number</div>
      <div>{{buyer.phone_no}}</div>
    </div>
    <div class="py-2">
      <div class="font-weight-bold">District</div>
      <div>{{buyer.district}}</div>
    </div>
    <div class="py-2">
      <div class="font-weight-bold">Village</div>
      <div>{{buyer.village}}</div>
    </div>
    <h3>Mobile Money Payments</h3>
    <div class="py-2">
      <div class="font-weight-bold">Name</div>
      <div>{{buyer.name}}</div>
    </div>
    <div>
      <div class="font-weight-bold">Phone Number</div>
      <div>{{buyer.phone_no}}</div> 
    </div>
  </div>
</template>
<script>
export default {
  computed:{
    buyer(){
      return this.$store.getters["buyera/getProfile"];
    }
  },
  data() {
    return {
      form: {
        name: "Asiimwe Bob",
        dob: "1997-01-19",
        contact: "+256783838299",
        district: "Buikwe",
        village: "Buikwe village",
        momo: {
          phone: "+256783838299",
          name: "Asiimwe Bob",
        },
      },
    };
  },
};
</script>
